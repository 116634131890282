<template>
	<div class="carannualinspectioninformation">
		<el-card class="box-card father-card" shadow="never">
			<!-- 头部区域 -->
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<!-- 搜索框 -->
					<!-- 	<el-form-item label="选择机构">
						<el-select v-model="form.institutions" placeholder="请选择机构">
							<el-option v-for="item in institutionsOptions" :key="item.uuid" :label="item.companyName"
								:value="item.companyName">
							</el-option>
						</el-select>
					</el-form-item> -->
					<!-- 按钮区域 -->
					<el-form-item>
						<!-- <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button> -->
						<el-button type="primary" icon="el-icon-plus" @click="addClick">添加
						</el-button>
					</el-form-item>
				</el-form>
			</el-row>
			<!-- 表格区域 -->
			<el-row class="tab">
				<el-table ref="multipleTable" :data="tabData" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowClass">
					<el-table-column prop="companyName" label="企业名称" align="center"></el-table-column>
					<el-table-column prop="divideRatio" label="分成比例(%)" align="center"></el-table-column>
					<el-table-column prop="personDivideRatio" label="人员分成比例(%)" align="center"></el-table-column>
					<el-table-column prop="divideType" label="提成类型" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.divideType == 1">比例</div>
							<div v-if="scope.row.divideType == 2">固定值</div>
						</template>
					</el-table-column>
					<el-table-column prop="status" label="状态" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.status == 0">新加</div>
							<div v-if="scope.row.status == 1">正常</div>
							<div v-if="scope.row.status == 2">停用</div>
						</template>
					</el-table-column>
					<!-- 		<el-table-column prop="createBy" label="创建人" align="center"></el-table-column>
					<el-table-column prop="updateBy" label="修改人" align="center"></el-table-column> -->
					<el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
					<el-table-column prop="updateTime" label="修改时间" align="center"></el-table-column>
					<el-table-column prop="remark" label="备注" align="center"></el-table-column>

					<el-table-column label="操作" align="center" min-width="200px">
						<template slot-scope="scope">
							<el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
							<el-button size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改
							</el-button>
							<el-button size="mini" type="danger" icon="el-icon-delete-solid" @click="open(scope.row)">删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页区域 -->
				<el-pagination background @size-change="handleSizeChange" @current-change="handlePageNumChange"
					:current-page="paging.PageNo" :page-sizes="[10,15, 20, 50]" :page-size="paging.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
			</el-row>
			<!-- 机构详情弹出框 -->
			<el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog" width="40%">
				<span slot="title" class="dialog-Title">详情</span>
				<el-form :model="selectForm" inline ref="selectForm" :rules="selectFormRules" class="selectForm">
					<!-- 第一行 -->
					<el-row>
						<el-form-item label="机构名称" prop="companyName">
							<el-select v-model="selectForm.companyName" placeholder="" :disabled="isDisabled"
								style="width: 200px;">
								<el-option v-for="item in institutionsOptions" :key="item.uuid"
									:label="item.companyName" :value="item.companyName">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="分成比例(%)">
							<el-input v-model="selectForm.divideRatio" autocomplete="off" :disabled="isDisabled"
								style="width: 200px;">
							</el-input>
						</el-form-item>
					</el-row>
					<!-- 第二行 -->

					<el-row>
						<el-form-item label="提成类型">
							<!-- <el-input v-model="selectForm.divedeType" autocomplete="off" :disabled="isDisabled">
							</el-input> -->
							<el-select v-model="selectForm.divideType" placeholder="" :disabled="isDisabled"
								style="width: 200px;">
								<el-option v-for="item in checkReceiverOptions" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="人员分成比例(%)">
							<el-input v-model="selectForm.personDivideRatio" autocomplete="off" :disabled="isDisabled"
								style="width: 180px;">
							</el-input>
						</el-form-item>
					</el-row>
					<!-- 第三行 -->
					<el-row>
						<el-form-item label="状态">
							<el-select v-model="selectForm.status" placeholder="" :disabled="isDisabled"
								style="width: 230px;">
								<el-option v-for="item in stateOption" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="备注">
							<el-input v-model="selectForm.remark" autocomplete="off" style="width: 230px;"
								type="textarea" :rows="3" :disabled="isDisabled"></el-input>
						</el-form-item>
					</el-row>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="sure()">确 认</el-button>
				</div>
			</el-dialog>
			<!-- 添加弹出框 -->
			<el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()" width="40%">
				<span slot="title" class="dialog-Title">添加</span>
				<el-form :model="addForm" inline ref="addForm" class="addForm" :rules="addFormRules">
					<!-- 第一行 -->
					<el-row>
						<el-form-item label="机构名称" prop="companyName">
							<el-select v-model="addForm.companyId" placeholder="请选择机构" @change="addChange"
								style="width: 200px;">
								<el-option v-for="item in institutionsOptions" :key="item.uuid"
									:label="item.companyName" :value="item.uuid">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="分成比例(%)" prop="divideRatio">
							<el-input v-model="addForm.divideRatio" autocomplete="off" style="width: 200px;"></el-input>
						</el-form-item>

					</el-row>
					<!-- 第二行 -->
					<el-row>
						<el-form-item label="提成类型">
							<el-select v-model="addForm.divideType" placeholder="" @change="checkReceiverChange"
								style="width: 200px;">
								<el-option v-for="item in checkReceiverOptions" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="人员分成比例(%)" prop="personDivideRatio">
							<el-input v-model="addForm.personDivideRatio" autocomplete="off" style="width: 180px;">
							</el-input>
						</el-form-item>
					</el-row>
					<!-- 第三行 -->
					<el-row>
						<el-form-item label="备注" prop="remark">
							<el-input v-model="addForm.remark" autocomplete="off" style="width: 230px;" type="textarea"
								:rows="3"></el-input>
						</el-form-item>
					</el-row>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="addSure()">确 认</el-button>
				</div>
			</el-dialog>
			<!-- 附件预览弹出框 -->
			<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt />
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'carannualinspectioninformation',
  components: {},
  data () {
    return {
      tabData: [], // 表格数据源
      form: {
        institutions: ''
      }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {
        divideType: 1
      }, // 添加表单
      // is: true,
      addFormRules: {
        companyName: [{
          required: true,
          message: '请选择机构',
          trigger: 'blur'
        }],
        divideRatio: [{
          required: true,
          message: '请输入分成比例',
          trigger: 'blur'
        }],
        createBy: [{
          required: true,
          message: '请输入创建人',
          trigger: 'blur'
        }],
        updateBy: [{
          required: true,
          message: '请输入修改人',
          trigger: 'blur'
        }],
        createTime: [{
          required: true,
          message: '请选择创建时间',
          trigger: 'blur'
        }],
        updateTime: [{
          required: true,
          message: '请选择修改时间',
          trigger: 'blur'
        }],
        personDivideRatio: [{
          required: true,
          message: '请输入人员分成比例',
          trigger: 'blur'
        }]
      }, // 添加表单校验规则
      selectFormRules: {
        companyName: [{
          required: true,
          message: '请选择机构名称',
          trigger: 'blur'
        }],
        divideRatio: [{
          required: true,
          message: '请输入分成比例',
          trigger: 'blur'
        }],
        updateBy: [{
          required: true,
          message: '请输入修改人',
          trigger: 'blur'
        }],
        createTime: [{
          required: true,
          message: '请选择创建时间',
          trigger: 'blur'
        }],
        updateTime: [{
          required: true,
          message: '请选择创建时间',
          trigger: 'blur'
        }]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      statusList: [{
        label: '正常',
        status: 1
      }, {
        label: '停用',
        status: 2
      }],
      institutionsOptions: [], // 机构列表
      checkReceiverOptions: [{ // 提成类型
        label: '比例',
        value: 1
      }, {
        label: '固定值',
        value: 2
      }],
      stateOption: [{ // 状态
        label: '新加',
        value: '0'
      }, {
        label: '正常',
        value: '1'
      }, {
        label: '停用',
        value: '2'
      }]
    }
  },
  created () {
    this.getInstitutions()
    this.getSetList()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({
      row,
      rowIndex
    }) {
      return 'background:#e4eaec'
    },
    // 获取机构信息
    async getInstitutions () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompany', {
        // "pageSize": this.paging.pageSize,
        // "pageNo": this.paging.PageNo,
        companyName: ''
        // "status": 1
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取机构列表失败')
      this.institutionsOptions = res.data
      // this.total = +res.pageBean.pageDataCount;
    },
    // 获取列表信息
    async getSetList () {
      const {
        data: res
      } = await this.$http.post('/pmService/tBillCompanyDivideSet/getTBillCompanyDivideSetList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo
        // companyName: ''
        // "status": 1

      })
      if (res.statusCode !== '200') return this.$message.error('获取列表失败')
      this.tabData = res.data
      // console.log(this.tabData, 'tabData')
      this.total = +res.pageBean.pageDataCount
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      if (this.form.carNum === '') {
        this.getSetList()
      } else {
        // 调接口，进行查询
        const {
          data: res
        } = await this.$http.post('/userManageServer/tSysCompany/selectCompany', {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          companyName: this.form.institutions
        })
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('查询失败')
        this.tabData = res.data
        this.total = +res.pageBean.pageDataCount
      }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getSetList()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getSetList()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      console.log(this.selectForm, 'selectForm')
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm('是否确认删除', '删除车辆', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const {
            data: res
          } = await this.$http.post(
            // '/pmService/tBillCompanyDivideSet/deleteTBillCompanyDivideSet', JSON.parse(JSON
            // 	.stringify(e)))
            '/pmService/tBillCompanyDivideSet/deleteTBillCompanyDivideSet', {
              uuid: e.uuid
            })
          // 判断是否删除成功
          if (res.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图
          this.getSetList()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) {
        return this.orderDialogFormVisible = false
      } else {
        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          this.selectForm.checkTime = this.formatTime(this.selectForm.checkTime,
            'yyyy-MM-dd hh:mm:ss')
          this.selectForm.checkInTime = this.formatTime(this.selectForm.checkInTime,
            'yyyy-MM-dd hh:mm:ss')
          this.selectForm.checkOutTime = this.formatTime(this.selectForm.checkOutTime,
            'yyyy-MM-dd hh:mm:ss')
          // 进行编辑操作
          const {
            data: res
          } = await this.$http.post(
            '/pmService/tBillCompanyDivideSet/updateTBillCompanyDivideSet', JSON.parse(
              JSON
                .stringify(this.selectForm)))
          console.log(res)
          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // 成功提示
          this.$message.success('修改成功')
          // 重新渲染数据
          this.getSetList()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 格式化时间
    formatTime (date, fmt) {
      var date = new Date(date)
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      var o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          var str = o[k] + ''
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
        }
      }
      return fmt
    },
    // 添加机构--确定按钮
    addSure () {
      // this.addForm.createTime = this.formatTime(this.addForm.createTime, 'yyyy-MM-dd hh:mm:ss')
      // this.addForm.companyName = '天启转运有限公司'
      // this.addForm.checkInTime = this.formatTime(this.addForm.checkInTime, 'yyyy-MM-dd hh:mm:ss')
      // this.addForm.checkOutTime = this.formatTime(this.addForm.checkOutTime, 'yyyy-MM-dd hh:mm:ss')
      this.addForm.status = 0
      this.addForm.createBy = sessionStorage.getItem('userId')
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        console.log(JSON.parse(JSON.stringify(this.addForm)))
        // 调接口，发送添加客户请求
        const {
          data: res
        } = await this.$http.post('/pmService/tBillCompanyDivideSet/addTBillCompanyDivideSet',
          JSON
            .parse(JSON.stringify(this.addForm)))
        console.log(res)
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getSetList()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.fileList = []
      this.$refs.addForm.resetFields()
    },
    handleRemove (file) {
      const fileList = this.$refs.upload.uploadFiles
      const index = fileList.findIndex(fileItem => {
        return fileItem.uid === file.uid
      })
      fileList.splice(index, 1)
      const i = this.fileList.findIndex(fileItem => {
        return fileItem.uid === file.uid
      })
      this.fileList.splice(i, 1)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 添加附件时触发
    handleChange (file, fileList) {
      if (fileList.length > 3) {
        this.$message.warning('最多上传三张图片')
        fileList.splice(fileList.length - 1, 1)
      } else {
        this.fileList.push(file)
        this.addForm.file = this.fileList
      }
    },
    addClick () {
      this.addDialogFormVisible = true
      // this.addForm.checkReceiver = 1
    },
    checkReceiverChange (v) {
      this.addForm.divideType = v
    },
    companyChange (item) {
      console.log(item)
    },
    addChange (v) {
      console.log(v)
      this.institutionsOptions.forEach(item => {
        if (item.uuid == v) {
          console.log(item)
          this.addForm.companyName = item.companyName
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
	.carannualinspectioninformation {
		width: 100%;
		height: 100%;

		//隐藏滚动条
		::-webkit-scrollbar {
			// display: none;
			/* Chrome Safari */
		}

		.father-card {
			.btns {
				margin-bottom: 10px;
			}

			.tab {
				/deep/ .el-table {
					margin-bottom: 20px;

					.el-table__row--striped td {
						background-color: #e4eaec !important;
					}

					.current-row>td {
						background-color: #66b1ff !important;
					}
				}

				.el-pagination {
					margin-bottom: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}
			}

			.add-dialog,
			.order-dialog {
				.dialog-Title {
					color: #0097fe;
					font-size: 20px;
					font-weight: 700;
				}

				/deep/ .el-form {
					.subtitle {
						color: #0097fe;
						font-size: 14px;
						font-weight: 700;
						margin-bottom: 20px;
						padding-left: 6px;
						border-left: 5px solid #0097fe;
					}

					.el-row {
						display: flex;

						.el-form-item {
							flex: 1;
							display: flex;

							.el-form-item__content {
								flex: 1;

								.el-input {
									width: 100%;

									.el-input__inner {
										width: 100%;
									}
								}

								.el-select {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
